.react-calendar {
  width: 350px;
  max-width: 100%;
  line-height: 1.125em;
  width: 296px;
  height: 100%;
  max-height: 370px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  padding: 24px;
  outline: none;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 13px;
  line-height: 13px;
  z-index: 1500;
}

.react-calendar-presets {
  margin-top: 16px;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
  box-shadow: none !important;
}

.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__spacer {
  flex: 1 1 auto;
}
.react-calendar__previous-button {
  position: relative;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 50%;
  background: transparent;
  overflow: visible;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  box-sizing: border-box;
}
.react-calendar__previous-button::after {
  border-left-width: 2px;
  transform: translateX(2px) rotate(-45deg);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  content: '';
  margin: 15.5px;
  border: 0 solid currentColor;
  border-top-width: 2px;
}
.react-calendar__mat-ripple {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  border-radius: inherit;
  z-index: 1;
  overflow: hidden;
}
.react-calendar__button-focus-overlay {
  transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1), background-color 200ms cubic-bezier(0.35, 0, 0.25, 1);
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days > :not(:nth-child(-n + 7)) {
  margin-top: 8px;
}
.react-calendar__month-view__days > :first-child {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.react-calendar__month-view__days > :last-child {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.react-calendar__month-view__days__day--weekend {
  color: #a3a3af;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 8px;
}
.react-calendar__tile {
  margin: 0 8px;
  font-size: 12px;
  transform: scale(1);
  max-width: 100%;
  text-align: center;
  padding: 0;
  background: none;
  width: 36px;
  height: 36px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
}
.react-calendar__tile:hover {
  background-color: rgba(38, 38, 38, 0.15);
  border-radius: 50%;
  border: solid 1px rgba(0, 0, 0, 0.38) !important;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: rgba(38, 38, 38, 0.15);
  border-radius: 50%;
}
.react-calendar__tile--now {
  border-color: rgba(0, 0, 0, 0.38) !important;
  border-width: 1px !important;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  border: solid 1px rgba(0, 0, 0, 0.38) !important;
  color: white;
  border-radius: 50%;
  background: #262626;
}
.react-calendar__tile--hasActive {
  background: #000000;
  color: white;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  border-radius: 50%;
  background: #1a1a1a;
}
.react-calendar__tile--active {
  background: #000000;
  color: white;
  border-radius: 0;
}
.react-calendar__tile--active:enabled:hover {
  border-radius: 0;
  background: #1a1a1a;
}
.react-calendar__tile--active:enabled:focus {
  border-radius: 50%;
  background: #1a1a1a;
}
.react-calendar__tile--rangeStart {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.react-calendar__tile--rangeStart:hover {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.react-calendar__tile--rangeEnd {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.react-calendar__tile--rangeEnd:hover {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  border-radius: 0;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.38);
  border-style: dashed none dashed none;
}
.react-calendar--selectRange .react-calendar__tile--rangeStart {
  border-radius: 50%;
}
.react-calendar--selectRange .react-calendar__tile--rangeEnd {
  border-radius: 50%;
}
